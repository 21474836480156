.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 60px auto;
  width: 1280px;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.text-section {
  flex: 1;
  margin: 20px 60px;
}

.image-section {
  flex: 1;
  margin: 20px 20px;
}

.text-section:nth-child(1) {
  /* flex: 1;
  margin: 20px 60px; */
  padding-right: 100px;
  text-align: left;
}

.text-section:nth-child(2) {
  /* flex: 1;
  margin: 20px 60px; */
  /* text-align: right; */

  padding-left: 60px;
  margin-top: -34px;
}

.text-section {
  color: #fff;
  max-width: 400px;
}

.image-section img {
  /* max-width: 400px; */
  width: 440px;
  height: 300px;
}
