.slider {
  width: 100%;
  height: var(--height);
  max-width: 1280px;
}

.slider ul {
  display: flex;
  width: 100%;
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}

.slider ul li {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  animation: autoRun 20s linear infinite;
  animation-delay: calc((1s / var(--quantity)) * (var(--position) -1));
}

.slider ul li img {
  width: 100%;
}

@keyframes autoRun {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}
