@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* background-color: #09090b; */
  background-color: #000;
}

:root {
  --width: 1000px;
  --height: 78px;
  --quantity: 1;
}

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 1.5px #a7977e;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px black;
  }
}

.pri_text {
  color: #dddac7;
}

.sec_text {
  color: #a7977e;
}

.sec_text_outline {
  color: rgb(167, 151, 126);
}

.pri_dark {
  color: #1c1e21;
}

.pri_dark_bg {
  background-color: #1c1e21;
}

/* .list:hover .item:not(:hover) {
  filter: grayscale(1) blur(10px);
} */

.collection:hover .item img:not(:hover) {
  filter: grayscale(1) blur(10px);
}

/* .list:hover .item {
  filter: grayscale(1) blur(10px);
  }
  
  .list:hover .item:hover {
    filter: none;
    } */

.img-hor-vert {
  /* -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1); */
  transform: scaleX(-1);
}

/* Anitmations */

.autoRotate {
  animation: autoRotateAnimation;
  animation-timeline: view();
  /* height: 400px !important;
  width: 400px !important; */
}

@keyframes autoRotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.autoShow {
  animation: autoShowAnimation both;
  animation-timeline: view(70% 5%);
}

@keyframes autoShowAnimation {
  from {
    opacity: 0;
    transform: translateY(200px) scale(0.3);
  }
  to {
    opacity: 1;
    transform: translate(0) scale(1);
  }
}

.autoBlur {
  animation: autoBlurAnimation linear both;
  animation-timeline: view();
}

@keyframes autoBlurAnimation {
  0% {
    filter: blur(40px);
  }
  45%,
  75% {
    filter: blur(0px);
  }
  100% {
    filter: blur(40px);
  }
}

.autoBlurHero {
  animation: autoBlurHeroAnimation linear both;
  animation-timeline: view();
}

@keyframes autoBlurHeroAnimation {
  0% {
    filter: blur(0px);
  }
  35%,
  65% {
    filter: blur(0px);
  }
  80% {
    filter: blur(40px);
  }
  100% {
    filter: blur(60px);
  }
}

.autoBlurLoading {
  --webkit-animation: autoBlurLoadingAnimation initial;
}

@keyframes blackblur {
  from {
    filter: blur(40px);
    color: transparent;
  }
  to {
    filter: blur(0px);
    color: #dddac7;
  }
}

.autoBlurLoading span:nth-last-child(1) {
  -webkit-animation: blackblur 1s 1 linear;
}
.autoBlurLoading span:nth-last-child(2) {
  -webkit-animation: blackblur 1.05s 1 linear;
}
.autoBlurLoading span:nth-last-child(3) {
  -webkit-animation: blackblur 1.1s 1 linear;
}
.autoBlurLoading span:nth-last-child(4) {
  -webkit-animation: blackblur 1.15s 1 linear;
}
.autoBlurLoading span:nth-last-child(5) {
  -webkit-animation: blackblur 1.2s 1 linear;
}
.autoBlurLoading span:nth-last-child(6) {
  -webkit-animation: blackblur 1.25s 1 linear;
}
.autoBlurLoading span:nth-last-child(7) {
  -webkit-animation: blackblur 1.3s 1 linear;
}
.autoBlurLoading span:nth-last-child(8) {
  -webkit-animation: blackblur 1.35s 1 linear;
}
.autoBlurLoading span:nth-last-child(9) {
  -webkit-animation: blackblur 1.4s 1 linear;
}
.autoBlurLoading span:nth-last-child(10) {
  -webkit-animation: blackblur 1.45s 1 linear;
}
.autoBlurLoading span:nth-last-child(11) {
  -webkit-animation: blackblur 1.5s 1 linear;
}
