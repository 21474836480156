.slide {
  display: flex;
  justify-content: center;
  padding: 40px 0px;
  flex-direction: column;
  max-width: 1180px;
  margin: 0 auto;
}

.slide_container {
  position: relative;
  width: 1180px;
}

.carousel-container {
  width: 100%;
  max-width: 1400px;
}

/* ---------- media queries ------------ */

@media (max-width: 1400px) {
  .slide_container {
    width: 98%;
  }
}

@media (max-width: 610px) {
  .slide {
    margin-top: 420px;
  }
}

@media (max-width: 360px) {
  .slide {
    margin-top: 460px;
  }
}

/* ---------- media queries ------------ */
